<template>
  <div>
    <button id="sports-btn" class="btn dropdown-toggle ddb dropdown-toggle dropdown-filter" type="button"
      data-toggle="modal" data-target="#sportModal"
      style="background-color: #0b0447; color: white; border: 1px solid #9495b3;border-radius: 0.5rem;">
      {{ selectedSport }}
    </button>
    <div class="modal fade" id="sportModal" tabindex="-1" aria-labelledby="sportModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title text-light" id="sportsModalLabel">
              Sports
            </h5>
            <span style="color: var(--yellow)" type="button" class="close" data-dismiss="modal">&times;</span>
          </div>
          <div style="
              margin: 1px;
                background-color: #0b0447;
                color: white;
                width: auto;
            " class="row modal-body text-center mx-auto">
            <div style="
                margin: 1px;
                background-color: #0b0447;
                border: 1px solid #8e8eb4;
                color: white;
                width: auto;
              " class="btn btn-sm col text-center" v-for="sport in sportsList" :key="sport.id" data-dismiss="modal"
              @click="setSportMenu(sport.id, sport.name)">
              <div class="item-wrapper">
                <img :src="sport.icon" class="menu-icon-sports-svg" />
                <span style="color: var(--yellow)"> {{ sport.displayName }} </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: null,
  data() {
    return {
      sportsList: [
        {
          id: 1,
          name: "Soccer",
          displayName: "Football",
          icon: "/img/icons/bb-icons/soccer.svg",
        },
        {
          id: 2,
          name: "Basketball",
          displayName: "Basketball",
          icon: "/img/icons/bb-icons/basketball.svg",
        },
        {
          id: 21,
          name: "Cricket",
          displayName: "Cricket",
          icon: "/img/icons/bb-icons/cricket.svg",
        },
        {
          id: 5,
          name: "Tennis",
          displayName: "Tennis",
          icon: "/img/icons/bb-icons/tennis-ball.svg",
        },
        {
          id: 4,
          name: "IceHockey",
          displayName: "Ice Hockey",
          icon: "/img/icons/bb-icons/ice-hockey.svg",
        },
        {
          id: 16,
          name: "AmericanFootball",
          displayName: "American Football",
          icon: "/img/icons/bb-icons/american-football.svg",
        },
        {
          id: 10,
          name: "Boxing",
          displayName: "Boxing",
          icon: "/img/icons/bb-icons/boxing-glove.svg",
        },
        {
          id: 31,
          name: "Badminton",
          displayName: "Badminton",
          icon: "/menu/Badminton.png",
        },
        {
          id: 12,
          name: "Rugby",
          displayName: "Rugby",
          icon: "/img/icons/bb-icons/rugby.svg",
        },
        {
          id: 23,
          name: "Volleyball",
          displayName: "Volleyball",
          icon: "/img/icons/bb-icons/volleyball.svg",
        },
        {
          id: 6,
          name: "Handball",
          displayName: "Handball",
          icon: "/menu/Handball.png",
        },
        {
          id: 117,
          name: "MixedMartialArts",
          displayName: "Mixed Martial Arts",
          icon: "/menu/Mma.png",
        },
        {
          id: 22,
          name: "Darts",
          displayName: "Darts",
          icon: "/menu/Darts.png",
        },
      ],
      selectedSport: "Soccer",
    };
  },
  methods: {
    getKey: function (index) {
      return Math.random()
        .toString(11)
        .replace("0.", "sport-" + index + "-");
    },
    setSport: function (sportID) {
      document.getElementById("sports-btn").click();
      this.$store.dispatch("setSportID", sportID);

      if (this.$store.state.current_page === "home") return;

      this.$router.push({ name: "home" });
    },

    setSportMenu: function (sport_id, sport_name) {
      // this.dismiss();
      //console.log('setSport ' + JSON.stringify(sport));

      // this.sport = sport;
      this.sport_name = sport_name;
      this.selectedSport = sport_name;
      // this.getMarket(sport.sport_id);
      this.$store.dispatch("setSportID", sport_id);
      this.$store.dispatch("setCurrentPage", sport_name);
      console.log(this.$store.state.sport_id);

      //if(this.$route.path !== "/"){

      this.$router.push({ name: "sport", params: {} });
      //}
    },
    getMenuTextClass: function (sportID) {
      return parseInt(this.$store.state.sport_id) === parseInt(sportID)
        ? "ml-2 item-wrapper-span-active"
        : "ml-2 item-wrapper-span";
    },
    isActiveMenu: function (name) {
      return this.current_page === name ? "menu-active" : "";
    },
    resetGames: function () {
      this.$store.dispatch("resetAllGames");

      if (this.current_page !== "home") {
        this.goHome();
      }
    },
  },
  computed: {
    iconSize: function () {
      return 20;
    },
    current_page: function () {
      return this.$store.state.current_page;
    },
    show_freebet: function () {
      var p = this.getProfile();
      if (!p) {
        return true;
      }

      return parseInt(p.b4) > 0;
    },
    sports: function () {
      var s = this.$store.state.sports;
      var t = [];

      this.jQuery.each(s, function (k, v) {
        if (v.sport_id > 1) {
          t.push(v);
        }
      });

      return t;
    },
  },
};
</script>



<style scoped>
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, 50px);
}

.modal-dialog {
  position: fixed !important;
  bottom: 0 !important;
  left: 0% !important;
  right: 0% !important;
  margin-bottom: 0 !important;
}

.modal.show .modal-dialog {
  transform: none;
}

.modal-content {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  /* background-color: var(--gray-white); */
  background-color: #0b0447;
}

.list-active {
  background-color: #f08b05;
}
</style>
